/*============================================*/
/*              Calendar Components           */
/*============================================*/
.mc-calendar {
	@include includeColors();
	display: flex;
	position: absolute;
	top: -100vh;
	left: 50vw;
	flex-direction: column;
	font-family: $calendar-font;
	font-weight: 500;
	overflow: hidden;
	user-select: none;
	visibility: hidden;
	background-color: $main-bg-color;

	&:focus {
		outline: none;
	}

	&--opened {
		visibility: visible;
	}

	&--inline {
		width: 100%;
		max-width: 300px;
		height: 100%;
		max-height: 325px;
		background: none;
		font-size: 1rem;
		box-shadow: 0 0 1px $date-inactive-text-color;
		// border: 1px solid $date-inactive-text-color;
		border-radius: 10px;
		z-index: 1002;

		@include for-desktop {
			transform: unset;
		}
	}

	&--modal {
		position: fixed;
		width: 100%;
		max-width: 425px;
		border-radius: 0 0 30px 30px;
		box-shadow: 0 10px 10px 5px rgba(0, 0, 0, 0.25);
		background-color: $main-theme-color;
		z-index: 1002;
		transform: translate(-50%, -65vh);
		transform: translate(-50%, -65vh);
		transition: transform 0.4s linear 0.2s, visibility 0s linear 0.5s, top 0s linear 0.5s;

		@include for-desktop {
			flex-direction: row;
			width: auto;
			max-width: unset;
			height: 400px;
			border-radius: 10px;
			// background-color: unset;
			box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
			opacity: 0;
			transform: translate(-50%, -50%) scale(0.9);
			transition: transform 0.3s, opacity 0.3s, visibility 0s linear 0.3s, top 0s linear 0.3s;
		}

		&#{$opened} {
			top: 0;
			transform: translate(-50%, 0);
			transition: unset;
			animation: slide-down 0.3s linear;

			@include for-desktop {
				top: 50vh;
				opacity: 1;
				visibility: visible;
				height: 95vh;
				max-height: 400px;
				transform: translate(-50%, -50%) scale(1);
				@include popup();
			}
		}
	}
	&--permanent {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
