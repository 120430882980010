body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#map {
  height: 100%;
  width: 100%;
  height: 750px;
}

.layout {
  height: 100%;
  width: 100%;
  background-color: rgb(241, 241, 241);
  display: none;
  flex-direction: column;
}
.nav-bar {
  display: flex;
  align-items: center;
}

.nav-bar-btn {
  border: none;
  font-size: 84px;
  padding: 0 12px;
  color: rgb(186, 186, 186);
  margin: 0;
  cursor: default;
  width: 50px;
  user-select: none;
  margin-top: 10px;
}

.nav-bar-btn:hover {
  cursor: pointer;
  color: rgb(148, 148, 148);
}

.container {
  height: 100%;
  position: relative;
}

.menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 42%;  
  height: 928px;
  padding: 0 16px;
  z-index: 10;
  background-color: rgb(241, 241, 241);
  overflow: scroll;
  max-height: 750px;
}

.menu-tabs {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid gray;
  cursor: default;
  height: 60px;
}

.menu-tabs h3:hover {
  cursor: pointer;
}

.menu-active-tab {
  border-bottom: 3px solid blue;
}

.menu-tab-body {
  /* padding-top: 16px; */
  max-width: 1100px;
  height: 680px !important;
  overflow-y: scroll;
}

.droppable-area {
  max-width: 400px;
  height: 200px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

#vehicleTable {
  width: 100%;
  display: flex;
  align-items: center;
}

#vehicleTable th,
#vehicleTable div,
#vehicleTable input {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gainsboro;
  height: 40px;
  padding: 16px 6px;
}
.routesTable,
.routesTableBody {
  width: 100%;
  display: flex;
  align-items: center;
}

.routesTable th,
.routesTable div,
.routesTable input,
.routesTableBody th,
.routesTableBody div,
.routesTableBody input {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gainsboro;
  height: 40px;
  padding: 16px 0px;
}

.routesTable th:last-child,
.routesTable div:last-child,
.routesTableBody th:last-child,
.routesTableBody div:last-child {
  width: 300%;
  display: flex;
  align-items: center;
}
.routeAndColor {
  height: 100%;
  width: 100%;
}

.pinConnectColor {
  width: 20px;
  height: 100%;
  color: transparent;
}

.addVehicleBtn {
  width: 120px;
  text-align: center;
  border: 1px solid gainsboro;
  color: rgb(143, 143, 143);
  margin: 16px 16px;
  padding: 8px 16px;
  border-radius: 6px;
}

.addVehicleBtn:hover {
  background-color: #b9b9b9;
  color: white;
  cursor: pointer;
}

.resetButtonContainer {
  width: 100%;
  display: flex;
  justify-content: end;
}

.resetRoutesBtn {
  border: 1px solid black;
  background: rgb(0, 0, 131);
  color: white;
  width: 150px;
  text-align: center;
  margin-top: 32px;
  padding: 10px 4px;
  border-radius: 6px;
  font-size: 18px;
}

.resetRoutesBtn:hover {
  background-color: rgb(0, 0, 168);
  color: white;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgb(133, 133, 133);
}

#vehicleBody {
  display: flex;
  width: 100%;
}

.gg-trash {
  scale: 1.8;
  margin-left: 5px;
}

.gg-trash:hover {
  cursor: pointer;
}

.dropdown-input {
  cursor: pointer;
  width: 100%;
  height: 74px;
  border: 1px solid #dcdcdc;
}

.applyBtn {
  color: white;
  background-color: rgb(0, 160, 255);
  font-size: 14px;
  border-radius: 4px;
  padding: 6px 12px;
}

.applyBtn:hover {
  cursor: pointer;
  background-color: rgb(0, 175, 255);
}
.applyBtn:disabled {
  cursor: default;
  background-color: rgb(236, 236, 236);
  color: #a2a2a2;
}

input:disabled {
  color: gray;
}

.fa-lock,
.fa-lock-open {
  width: 55px;
  font-size: 35px;
  margin-top: -2px;
}

.fa-lock-open:hover,
.fa-lock:hover {
  cursor: pointer;
}

.cardContainer {
  overflow-y: scroll;
  height: 270px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media (min-width: 1400px) {
  .cardContainer {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 1600px) {
  .cardContainer {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media (min-width: 1700px) {
  .cardContainer {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media (min-width: 2000px) {
  .cardContainer {
    grid-template-columns: repeat(10, 1fr);
  }
}

@media (min-width: 2400px) {
  .cardContainer {
    grid-template-columns: repeat(12, 1fr);
  }
}

.card {
  width: 170px;
  text-align: center;
  margin: 9px 5px 0px 0px;
  border-radius: 10px;
  position: relative;
}

.card:hover {
  cursor: pointer;
}

.card h2 {
  border-radius: 10px 10px 0 0;
  margin-bottom: 0;
  margin-top: 0px;
  /* padding: 10px 0; */
}

.card p {
  border-radius: 0 0 0px 0px;
  margin-top: 0;
  padding: 5px 0;
  margin-bottom: 10px;
}

.cardContent {
  display: none;
  width: 170px;
  background-color: white;
  position: absolute;
  top: 75px;
  z-index: 100;
  border: 1px solid rgb(184, 184, 184);
}

.infoCard {
  display: flex;
  justify-content: center;
}

.infoCard div:first-child {
  border-right: 1px solid rgb(217, 217, 217);
  width: 100%;
}

.infoCard div {
  width: 100%;
  height: 100%;
}

.infoCard div p {
  border-bottom: 1px solid rgb(217, 217, 217);
  width: 100%;
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 10px 0;
}

.resetRoutesBtn:disabled {
  background: #ececec;
  color: #a2a2a2;
  cursor: default;
  box-shadow: none;
}

.datePickerContainer {
  position: relative;
  width: 70px !important;
  border: none !important;
  margin-left: 6px;
  margin-right: -10px;
}

.datePickerContainer i {
  position: absolute;
  top: 17px;
  font-size: 35px;
  color: gray;
}

.datePicker {
  position: absolute;
  border: none !important;
  margin: 0 8px;
  /* width: 50 !important; */
  /* background: transparent; */
  /* z-index: 2; */
  width: 60px !important;
  font-size: 10px;

}
.datePicker:disabled{
  background: rgb(220, 220, 220);
}

.datePicker:hover {
  cursor: pointer;
}

.datePicker:focus {
  outline: none;
}

.datePicker::-webkit-calendar-picker-indicator {
  width: 50px !important;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-btn-container {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 10px;
  height: 220px;
}

.nav-btn-wrapper {
  margin-top: 10px;
}

.nav-btn {
  flex-direction: column;
  margin: 0 16px;
}

.nav-btn button {
  height: 40px;
  background-color: rgb(74, 179, 245);
  color: white;
  border: 1px solid rgb(171, 171, 171);
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
}

.nav-btn button:hover {
  background-color: rgb(0, 152, 247);
  cursor: pointer;
}

.bigCard {
  margin: 5px;
  /* padding: 16px; */
  height: 235px;
  width: 180px;
}

.h2BigCardRouteName {
  margin: 0;
  font-size: 16px;
  padding: 8px;
  text-align: center;
}

.pBigCardDate{
  margin: 0px !important;
}

.bigCard p {
  margin: 6px 0;
  text-align: center;
}

.bigInfoCard {
  display: flex;
  justify-content: space-around;
}

.cardHeadings {
  border-bottom: 1px solid black;
  font-style: italic;
}
.dateBackground {
  width: 100%;
  padding: 4px 0;
  background-color: orange;
}

.gaugeIcon {
  background: red;
  color: black;
  font-size: 24px;
  border-radius: 50%;
}

.overweight i {
  margin-left: 140px;
  font-size: 26px;
}

.availabilityTable {
  margin-top: 5px;
  padding-right: 4px;
  border-collapse: collapse;
  overflow-y: auto;
  display: block;
  height: 250px;
}

.availabilityTable th,
.availabilityTable td {
  border: 1px solid black;
  padding: 2px;
  text-align: center;
  max-width: 43.5px;
  overflow: hidden;
}

tr td:first-child {
  font-size: 13px;
}

.login-form {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-label {
  display: block;
  margin-bottom: 8px;
}

.login-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.login-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-btn:hover {
  background-color: #45a049;
}

.infoWindow {
  width: 180px;
}

.info {
  padding-left: 20px;
  font-size: 32px;
}

.info:hover {
  cursor: pointer;
  color: rgb(37, 37, 255);
}

.print {
  font-size: 32px;
  margin-left: 8px;
}

.print:hover {
  cursor: pointer;
  color: rgb(37, 37, 255);
}

.checkbox-container {
  width: 180px;
  text-align: center;
  padding-bottom: 12px;
}

.labelForFileInput {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.vehicleBtnContainer {
  display: flex;
}

.pCurrentFileFailed{
  color: red;
  text-decoration: none !important;
}

/* styles for route combos */
.divComboCheckboxContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

.divPinsContainer{
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
  column-gap: 2px;
  row-gap: 6px;
  max-height: 558px;
  max-width: 880px;
  /* padding-right: 12px; */
  overflow-y: auto;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
}

.divRowItem {
  /* width: 30%; */
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  /* border: 1px solid black; */
  overflow-y: auto;
  max-height: 530px;
  /* min-height: 60px; */
}
.divPinsContainer .divRowItem input {
  display: flex;
  /* align-self: flex-start; */
}
.checkboxSelectMapLocation{
  width: 30px;
  height: 19px;
  /* margin: 1px; */
  margin-left: -4px;
}

.labelRowItem{
  font-size: 12px;
  max-width: 205px;
  margin-left: 2px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.daysOverdue3{
  color: blue;
}

.daysOverdue5{
  color: red;
}

.divInvoiceNum{
  color: white;
  background-color: red;
  border: 1px solid black;
  margin-right: 0px;
  margin-left: 4px;
  width: 20px;
  height: 15px;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  font-size: 17px;
}

.rowItemDisabled{
  color: black;
  background-color: rgb(195, 195, 195);
}

.divButtonsContainer{
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin-bottom: 170px;
}

.tableComboRouteData{
  font-size: 15px;
}
.inputNazivrute, .inputUnesiputarinu{
  max-width: 100px;
}
.tdRouteNameRouteColor{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.divComboRouteColor{
  width: 22px;
  height: 22px;
  margin-left: 3px;
}
.inputVozilo{
  min-width: 80px;
}
/* END styles for route combos */