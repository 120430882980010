/*============================================*/
/*             Transition Variables           */
/*============================================*/
$transition-duration-m: 0.7s;
$transition-duration-s: 0.3s;
$transition-duration-n: 0s;

$transition-delay-m: 0.5s;
$transition-delay-s: 0.3s;
$transition-delay-n: 0s;

$easing: linear;
