/*============================================*/
/*                  CSS Resets                */
/*============================================*/

.mc-calendar {
	&,
	* {
		line-height: 1.2;
		&,
		&::before,
		&::after {
			box-sizing: border-box;
			margin: 0;
			padding: 0;
		}
	}
}
.mc-btn,
.mc-select__nav {
	background: none;
	border: 0;
	cursor: pointer;
	font: inherit;
	line-height: normal;
	overflow: visible;
	padding: 0;
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}
