/*============================================*/
/*                Date Components             */
/*============================================*/

.mc-date {
	$root: &;
	text-align: center;
	border-radius: 5px;
	font-weight: 300;
	width: calc(100% / 7);

	&--active {
		cursor: pointer;
		color: $date-active-text-color;

		&#{$root}--today {
			color: $date-active-today-text-color;
			background-color: $date-active-today-bg-color;
		}
		&#{$root}--picked {
			color: $date-active-pick-text-color;
			background-color: $date-active-pick-bg-color;
		}
	}

	&--inactive {
		color: $date-inactive-text-color;
		cursor: default;

		&#{$root}--today {
			box-shadow: 0 0 0 1px $date-inactive-today-bg-color;
			color: $date-inactive-today-text-color;
			box-shadow: none;
		}

		&#{$root}--picked {
			box-shadow: 0 0 0 1px $date-inactive-pick-bg-color;
			color: $date-inactive-pick-text-color;
			box-shadow: none;
		}
	}

	&--marked {
		color: $date-marked-text-color;
		font-weight: 500;
	}
}
