@import "https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap";
body {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  overflow: hidden;
}

#map {
  width: 100%;
  height: 750px;
}

.layout {
  background-color: #f1f1f1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: none;
}

.nav-bar {
  align-items: center;
  display: flex;
}

.nav-bar-btn {
  color: #bababa;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  border: none;
  width: 50px;
  margin: 10px 0 0;
  padding: 0 12px;
  font-size: 84px;
}

.nav-bar-btn:hover {
  cursor: pointer;
  color: #949494;
}

.container {
  height: 100%;
  position: relative;
}

.menu {
  z-index: 10;
  background-color: #f1f1f1;
  width: 42%;
  height: 928px;
  max-height: 750px;
  padding: 0 16px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: scroll;
}

.menu-tabs {
  cursor: default;
  border-bottom: 1px solid gray;
  justify-content: space-evenly;
  height: 60px;
  display: flex;
}

.menu-tabs h3:hover {
  cursor: pointer;
}

.menu-active-tab {
  border-bottom: 3px solid #00f;
}

.menu-tab-body {
  max-width: 1100px;
  overflow-y: scroll;
  height: 680px !important;
}

.droppable-area {
  border: 2px dashed #ccc;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  height: 200px;
  margin: 20px 0;
  display: flex;
}

#vehicleTable {
  align-items: center;
  width: 100%;
  display: flex;
}

#vehicleTable th, #vehicleTable div, #vehicleTable input {
  text-align: center;
  border: 1px solid #dcdcdc;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 16px 6px;
  display: flex;
}

.routesTable, .routesTableBody {
  align-items: center;
  width: 100%;
  display: flex;
}

.routesTable th, .routesTable div, .routesTable input, .routesTableBody th, .routesTableBody div, .routesTableBody input {
  text-align: center;
  border: 1px solid #dcdcdc;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 16px 0;
  display: flex;
}

.routesTable th:last-child, .routesTable div:last-child, .routesTableBody th:last-child, .routesTableBody div:last-child {
  align-items: center;
  width: 300%;
  display: flex;
}

.routeAndColor {
  width: 100%;
  height: 100%;
}

.pinConnectColor {
  color: #0000;
  width: 20px;
  height: 100%;
}

.addVehicleBtn {
  text-align: center;
  color: #8f8f8f;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  width: 120px;
  margin: 16px;
  padding: 8px 16px;
}

.addVehicleBtn:hover {
  color: #fff;
  cursor: pointer;
  background-color: #b9b9b9;
}

.resetButtonContainer {
  justify-content: end;
  width: 100%;
  display: flex;
}

.resetRoutesBtn {
  color: #fff;
  text-align: center;
  background: #000083;
  border: 1px solid #000;
  border-radius: 6px;
  width: 150px;
  margin-top: 32px;
  padding: 10px 4px;
  font-size: 18px;
}

.resetRoutesBtn:hover {
  color: #fff;
  cursor: pointer;
  background-color: #0000a8;
  box-shadow: 2px 2px 2px #858585;
}

#vehicleBody {
  width: 100%;
  display: flex;
}

.gg-trash {
  margin-left: 5px;
  scale: 1.8;
}

.gg-trash:hover {
  cursor: pointer;
}

.dropdown-input {
  cursor: pointer;
  border: 1px solid #dcdcdc;
  width: 100%;
  height: 74px;
}

.applyBtn {
  color: #fff;
  background-color: #00a0ff;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
}

.applyBtn:hover {
  cursor: pointer;
  background-color: #00afff;
}

.applyBtn:disabled {
  cursor: default;
  color: #a2a2a2;
  background-color: #ececec;
}

input:disabled {
  color: gray;
}

.fa-lock, .fa-lock-open {
  width: 55px;
  margin-top: -2px;
  font-size: 35px;
}

.fa-lock-open:hover, .fa-lock:hover {
  cursor: pointer;
}

.cardContainer {
  grid-template-columns: repeat(4, 1fr);
  height: 270px;
  display: grid;
  overflow-y: scroll;
}

@media (width >= 1400px) {
  .cardContainer {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (width >= 1600px) {
  .cardContainer {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media (width >= 1700px) {
  .cardContainer {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media (width >= 2000px) {
  .cardContainer {
    grid-template-columns: repeat(10, 1fr);
  }
}

@media (width >= 2400px) {
  .cardContainer {
    grid-template-columns: repeat(12, 1fr);
  }
}

.card {
  text-align: center;
  border-radius: 10px;
  width: 170px;
  margin: 9px 5px 0 0;
  position: relative;
}

.card:hover {
  cursor: pointer;
}

.card h2 {
  border-radius: 10px 10px 0 0;
  margin-top: 0;
  margin-bottom: 0;
}

.card p {
  border-radius: 0;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 5px 0;
}

.cardContent {
  z-index: 100;
  background-color: #fff;
  border: 1px solid #b8b8b8;
  width: 170px;
  display: none;
  position: absolute;
  top: 75px;
}

.infoCard {
  justify-content: center;
  display: flex;
}

.infoCard div:first-child {
  border-right: 1px solid #d9d9d9;
  width: 100%;
}

.infoCard div {
  width: 100%;
  height: 100%;
}

.infoCard div p {
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding: 10px 0;
  font-size: 12px;
}

.resetRoutesBtn:disabled {
  color: #a2a2a2;
  cursor: default;
  box-shadow: none;
  background: #ececec;
}

.datePickerContainer {
  margin-left: 6px;
  margin-right: -10px;
  position: relative;
  border: none !important;
  width: 70px !important;
}

.datePickerContainer i {
  color: gray;
  font-size: 35px;
  position: absolute;
  top: 17px;
}

.datePicker {
  margin: 0 8px;
  font-size: 10px;
  position: absolute;
  border: none !important;
  width: 60px !important;
}

.datePicker:disabled {
  background: #dcdcdc;
}

.datePicker:hover {
  cursor: pointer;
}

.datePicker:focus {
  outline: none;
}

.datePicker::-webkit-calendar-picker-indicator {
  width: 50px !important;
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nav-btn-container {
  flex-direction: column-reverse;
  height: 220px;
  padding-bottom: 10px;
  display: flex;
}

.nav-btn-wrapper {
  margin-top: 10px;
}

.nav-btn {
  flex-direction: column;
  margin: 0 16px;
}

.nav-btn button {
  color: #fff;
  background-color: #4ab3f5;
  border: 1px solid #ababab;
  border-radius: 8px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
}

.nav-btn button:hover {
  cursor: pointer;
  background-color: #0098f7;
}

.bigCard {
  width: 180px;
  height: 235px;
  margin: 5px;
}

.h2BigCardRouteName {
  text-align: center;
  margin: 0;
  padding: 8px;
  font-size: 16px;
}

.pBigCardDate {
  margin: 0 !important;
}

.bigCard p {
  text-align: center;
  margin: 6px 0;
}

.bigInfoCard {
  justify-content: space-around;
  display: flex;
}

.cardHeadings {
  border-bottom: 1px solid #000;
  font-style: italic;
}

.dateBackground {
  background-color: orange;
  width: 100%;
  padding: 4px 0;
}

.gaugeIcon {
  color: #000;
  background: red;
  border-radius: 50%;
  font-size: 24px;
}

.overweight i {
  margin-left: 140px;
  font-size: 26px;
}

.availabilityTable {
  border-collapse: collapse;
  height: 250px;
  margin-top: 5px;
  padding-right: 4px;
  display: block;
  overflow-y: auto;
}

.availabilityTable th, .availabilityTable td {
  text-align: center;
  border: 1px solid #000;
  max-width: 43.5px;
  padding: 2px;
  overflow: hidden;
}

tr td:first-child {
  font-size: 13px;
}

.login-form {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px #0000001a;
}

.form-label {
  margin-bottom: 8px;
  display: block;
}

.login-input {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 16px;
  padding: 8px;
}

.login-btn {
  color: #fff;
  cursor: pointer;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
}

.login-btn:hover {
  background-color: #45a049;
}

.infoWindow {
  width: 180px;
}

.info {
  padding-left: 20px;
  font-size: 32px;
}

.info:hover {
  cursor: pointer;
  color: #2525ff;
}

.print {
  margin-left: 8px;
  font-size: 32px;
}

.print:hover {
  cursor: pointer;
  color: #2525ff;
}

.checkbox-container {
  text-align: center;
  width: 180px;
  padding-bottom: 12px;
}

.labelForFileInput {
  color: #00f;
  cursor: pointer;
  text-decoration: underline;
}

.vehicleBtnContainer {
  display: flex;
}

.pCurrentFileFailed {
  color: red;
  text-decoration: none !important;
}

.divComboCheckboxContainer {
  text-align: center;
  flex-direction: row;
  place-content: center;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.divPinsContainer {
  flex-flow: column wrap;
  place-content: flex-start;
  gap: 6px 2px;
  max-width: 880px;
  max-height: 558px;
  display: flex;
  overflow-y: auto;
}

.divRowItem {
  flex-direction: row;
  align-items: center;
  max-height: 530px;
  display: flex;
  overflow-y: auto;
}

.divPinsContainer .divRowItem input {
  display: flex;
}

.checkboxSelectMapLocation {
  width: 30px;
  height: 19px;
  margin-left: -4px;
}

.labelRowItem {
  flex-flow: wrap;
  max-width: 205px;
  margin-left: 2px;
  font-size: 12px;
  display: flex;
}

.daysOverdue3 {
  color: #00f;
}

.daysOverdue5 {
  color: red;
}

.divInvoiceNum {
  color: #fff;
  text-align: center;
  background-color: red;
  border: 1px solid #000;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 15px;
  margin-left: 4px;
  margin-right: 0;
  font-size: 17px;
  display: flex;
}

.rowItemDisabled {
  color: #000;
  background-color: #c3c3c3;
}

.divButtonsContainer {
  justify-content: flex-end;
  column-gap: 10px;
  margin-bottom: 170px;
  display: flex;
}

.tableComboRouteData {
  font-size: 15px;
}

.inputNazivrute, .inputUnesiputarinu {
  max-width: 100px;
}

.tdRouteNameRouteColor {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.divComboRouteColor {
  width: 22px;
  height: 22px;
  margin-left: 3px;
}

.inputVozilo {
  min-width: 80px;
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  white-space: normal;
  background-color: #333;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  color: #333;
  width: 16px;
  height: 16px;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-.5em);
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(.5em);
  }
}

@keyframes slide-down {
  0% {
    transform: translate(-50%, -400px);
  }

  100% {
    transform: translate(-50%);
  }
}

@keyframes zoom-in {
  0% {
    transform: translate(-50%, -50%)scale(.9);
  }

  100% {
    transform: translate(-50%, -50%)scale(1);
  }
}

@keyframes stretch {
  0% {
    max-height: 0;
  }

  50% {
    max-height: 0;
  }

  100% {
    max-height: 175px;
  }
}

@keyframes slide-left-in {
  from {
    transform: translateX(100px);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-left-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100px);
  }
}

@keyframes slide-right-in {
  from {
    transform: translateX(-100px);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-right-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100px);
  }
}

.mc-calendar, .mc-calendar * {
  line-height: 1.2;
}

.mc-calendar, .mc-calendar:before, .mc-calendar:after, .mc-calendar *, .mc-calendar :before, .mc-calendar :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.mc-btn, .mc-select__nav {
  cursor: pointer;
  font: inherit;
  background: none;
  border: 0;
  padding: 0;
  line-height: normal;
  overflow: visible;
}

.mc-btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.mc-select__nav::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.mc-calendar h1, .mc-calendar h2, .mc-calendar h3 {
  font-weight: 500;
}

.mc-container {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.mc-calendar {
  --mc-theme-color: #38ada9;
  --mc-main-bg: #f5f5f6;
  --mc-active-text-color: #000;
  --mc-inactive-text-color: #000c;
  --mc-display-foreground: #fffc;
  --mc-display-background: #38ada9;
  --mc-picker-foreground: #000;
  --mc-picker-background: #f5f5f6;
  --mc-picker-header-active: #818181;
  --mc-picker-header-inactive: #0003;
  --mc-weekday-foreground: #38ada9;
  --mc-btn-success-foreground: #38ada9;
  --mc-btn-danger-foreground: #e65151;
  --mc-date-active-def-foreground: #000;
  --mc-date-active-pick-foreground: #fff;
  --mc-date-active-pick-background: #38ada9;
  --mc-date-active-today-foreground: #000;
  --mc-date-active-today-background: #0003;
  --mc-date-inactive-def-foreground: #0003;
  --mc-date-inactive-pick-foreground: #38ada9;
  --mc-date-inactive-pick-background: #38ada9;
  --mc-date-inactive-today-foreground: #0003;
  --mc-date-inactive-today-background: #0003;
  --mc-date-marcked-foreground: #38ada9;
  --mc-prev-active-def-foreground: #000;
  --mc-prev-active-pick-foreground: #000;
  --mc-prev-active-pick-background: #0003;
  --mc-prev-inactive-def-foreground: #0003;
  --mc-prev-inactive-pick-foreground: #0003;
  --mc-prev-inactive-pick-background: #0003;
  -webkit-user-select: none;
  user-select: none;
  visibility: hidden;
  background-color: var(--mc-main-bg);
  flex-direction: column;
  font-family: Maven Pro, sans-serif;
  font-weight: 500;
  display: flex;
  position: absolute;
  top: -100vh;
  left: 50vw;
  overflow: hidden;
}

.mc-calendar:focus {
  outline: none;
}

.mc-calendar--opened {
  visibility: visible;
}

.mc-calendar--inline {
  box-shadow: 0 0 1px var(--mc-date-inactive-def-foreground);
  z-index: 1002;
  background: none;
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  height: 100%;
  max-height: 325px;
  font-size: 1rem;
}

@media (width >= 625px) {
  .mc-calendar--inline {
    transform: unset;
  }
}

.mc-calendar--modal {
  background-color: var(--mc-theme-color);
  z-index: 1002;
  border-radius: 0 0 30px 30px;
  width: 100%;
  max-width: 425px;
  transition: transform .4s linear .2s, visibility 0s linear .5s, top 0s linear .5s;
  position: fixed;
  transform: translate(-50%, -65vh);
  box-shadow: 0 10px 10px 5px #00000040;
}

@media (width >= 625px) {
  .mc-calendar--modal {
    max-width: unset;
    opacity: 0;
    border-radius: 10px;
    flex-direction: row;
    width: auto;
    height: 400px;
    transition: transform .3s, opacity .3s, visibility 0s linear .3s, top 0s linear .3s;
    transform: translate(-50%, -50%)scale(.9);
    box-shadow: 0 0 30px #0000004d;
  }
}

.mc-calendar--modal.mc-calendar--opened {
  transition: unset;
  animation: .3s linear slide-down;
  top: 0;
  transform: translate(-50%);
}

@media (width >= 625px) {
  .mc-calendar--modal.mc-calendar--opened {
    opacity: 1;
    visibility: visible;
    height: 95vh;
    max-height: 400px;
    animation: .1s linear zoom-in;
    top: 50vh;
    transform: translate(-50%, -50%)scale(1);
  }
}

.mc-calendar--permanent {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.mc-display {
  color: var(--mc-display-foreground);
  display: none;
}

.mc-calendar--modal .mc-display {
  flex-direction: column;
  max-height: 0;
  transition: max-height .2s linear;
  display: flex;
}

@media (width >= 625px) {
  .mc-calendar--modal .mc-display {
    max-height: unset;
    background-color: var(--mc-display-background);
    width: 200px;
    height: 100%;
  }
}

.mc-calendar--modal.mc-calendar--opened .mc-display {
  max-height: 175px;
  animation: .4s stretch;
}

@media (width >= 625px) {
  .mc-calendar--modal.mc-calendar--opened .mc-display {
    max-height: unset;
    animation: unset;
    transition: unset;
  }
}

.mc-display__body {
  justify-content: space-between;
  align-items: center;
  padding: .5em 0;
  display: flex;
}

@media (width >= 625px) {
  .mc-display__body {
    flex-direction: column;
    height: 100%;
    padding: 0;
  }
}

.mc-display__header {
  background-color: #0003;
}

@media (width >= 625px) {
  .mc-display[data-target="month"] .mc-display__header, .mc-display[data-target="year"] .mc-display__header {
    display: none;
  }
}

.mc-display__day {
  text-align: center;
  padding: .5em 0;
  line-height: 1;
}

@supports not (font-size: clamp(1rem, 8vw, 1.25rem)) {
  .mc-display__day {
    font-size: 8vw;
  }

  @media (width <= 12.5rem) {
    .mc-display__day {
      font-size: 1rem;
    }
  }

  @media (width >= 15.625rem) {
    .mc-display__day {
      font-size: 1.25rem;
    }
  }
}

@supports (font-size: clamp(1rem, 8vw, 1.25rem)) {
  .mc-display__day {
    font-size: clamp(1rem, 8vw, 1.25rem);
  }
}

.mc-display[data-target="month"] .mc-display__day, .mc-display[data-target="year"] .mc-display__day {
  visibility: hidden;
}

@media (width >= 625px) {
  .mc-display__day {
    padding: 1em 0;
  }
}

.mc-display__data {
  width: 50%;
  display: flex;
}

@media (width >= 625px) {
  .mc-display__data {
    text-align: center;
    width: 100%;
    height: 50%;
  }
}

.mc-display__data--primary {
  justify-content: flex-end;
}

.mc-display[data-target="month"] .mc-display__data--primary, .mc-display[data-target="year"] .mc-display__data--primary {
  display: none;
}

@media (width >= 625px) {
  .mc-display__data--primary {
    justify-content: center;
    align-items: flex-end;
  }
}

.mc-display__data--secondary {
  flex-direction: column;
}

.mc-display[data-target="month"] .mc-display__data--secondary, .mc-display[data-target="year"] .mc-display__data--secondary {
  text-align: center;
  width: 100%;
}

@media (width >= 625px) {
  .mc-display[data-target="month"] .mc-display__data--secondary, .mc-display[data-target="year"] .mc-display__data--secondary {
    justify-content: center;
    height: 100%;
  }

  .mc-display__data--secondary {
    justify-content: space-between;
  }
}

.mc-display__date {
  line-height: 1;
}

@supports not (font-size: clamp(5rem, 40vw, 7rem)) {
  .mc-display__date {
    font-size: 40vw;
  }

  @media (width <= 12.5rem) {
    .mc-display__date {
      font-size: 5rem;
    }
  }

  @media (width >= 17.5rem) {
    .mc-display__date {
      font-size: 7rem;
    }
  }
}

@supports (font-size: clamp(5rem, 40vw, 7rem)) {
  .mc-display__date {
    font-size: clamp(5rem, 40vw, 7rem);
  }
}

@supports not (font-size: clamp(1.2rem, 9vw, 1.5rem)) {
  .mc-display__month {
    font-size: 9vw;
  }

  @media (width <= 13.3333rem) {
    .mc-display__month {
      font-size: 1.2rem;
    }
  }

  @media (width >= 16.6667rem) {
    .mc-display__month {
      font-size: 1.5rem;
    }
  }
}

@supports (font-size: clamp(1.2rem, 9vw, 1.5rem)) {
  .mc-display__month {
    font-size: clamp(1.2rem, 9vw, 1.5rem);
  }
}

.mc-display[data-target="year"] .mc-display__month {
  display: none;
}

@media (width >= 625px) {
  .mc-display__month {
    height: auto;
  }
}

.mc-display__year {
  line-height: .8;
}

@supports not (font-size: clamp(2.4rem, 18vw, 3rem)) {
  .mc-display__year {
    font-size: 18vw;
  }

  @media (width <= 13.3333rem) {
    .mc-display__year {
      font-size: 2.4rem;
    }
  }

  @media (width >= 16.6667rem) {
    .mc-display__year {
      font-size: 3rem;
    }
  }
}

@supports (font-size: clamp(2.4rem, 18vw, 3rem)) {
  .mc-display__year {
    font-size: clamp(2.4rem, 18vw, 3rem);
  }
}

.mc-display[data-target="year"] .mc-display__year {
  padding: .3em 0;
}

@media (width >= 625px) {
  .mc-display__year {
    height: auto;
    padding: .5em 0;
  }
}

.mc-picker {
  background-color: var(--mc-picker-background);
  color: var(--mc-picker-foreground);
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.mc-calendar--modal .mc-picker {
  border-radius: 30px 30px 0 0;
  height: 65vh;
  max-height: 400px;
}

@media (width >= 625px) {
  .mc-calendar--modal .mc-picker {
    border-radius: unset;
    justify-content: center;
    align-items: flex-end;
    width: 425px;
    height: 100%;
  }
}

.mc-calendar--inline .mc-picker {
  border-radius: unset;
}

.mc-picker__header {
  color: var(--mc-picker-header-active);
  justify-content: center;
  padding: 1em 0 .5em;
  display: flex;
}

@supports not (font-size: clamp(1rem, 8vw, 1.25rem)) {
  .mc-picker__header {
    font-size: 8vw;
  }

  @media (width <= 12.5rem) {
    .mc-picker__header {
      font-size: 1rem;
    }
  }

  @media (width >= 15.625rem) {
    .mc-picker__header {
      font-size: 1.25rem;
    }
  }
}

@supports (font-size: clamp(1rem, 8vw, 1.25rem)) {
  .mc-picker__header {
    font-size: clamp(1rem, 8vw, 1.25rem);
  }
}

@media (width >= 625px) {
  .mc-calendar--modal .mc-picker__header {
    justify-content: space-between;
    padding: .7em 0;
  }
}

.mc-calendar--inline .mc-picker__header {
  padding: .7em 0 0;
  font-size: 1rem;
}

.mc-picker__header .icon-angle {
  height: calc(8vw + .25rem);
  min-height: 1.75rem;
  max-height: 2rem;
}

.mc-picker__body {
  flex: 1 0;
  width: 100%;
  position: relative;
}

.mc-picker__footer {
  justify-content: space-between;
  padding: 1em 0 2em;
  display: flex;
}

.mc-calendar--inline .mc-picker__footer {
  padding: .5em 0 1em;
}

.mc-select[data-target="year"] .mc-select__month {
  display: none;
}

.mc-select[data-target="year"] .mc-select__year {
  width: 100%;
}

.mc-select[data-target="year"] .mc-select__year .mc-select__data--year {
  max-width: unset;
  min-width: unset;
  justify-content: center;
  width: 75%;
}

.mc-select[data-target="year"] .mc-select__year .mc-select__nav {
  display: flex;
  position: relative;
}

.mc-select__month, .mc-select__year {
  align-items: center;
  display: flex;
}

.mc-select__nav {
  outline: revert;
  color: var(--mc-picker-header-active);
  align-items: center;
  padding: 0 1em;
  text-decoration: none;
  display: flex;
  position: absolute;
}

@media (width >= 625px) {
  .mc-calendar--modal .mc-select__nav {
    position: relative;
  }
}

.mc-select__nav:focus {
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.mc-select__nav--inactive {
  color: var(--mc-picker-header-inactive);
  cursor: default;
}

.mc-select__nav--inactive:active {
  transform: none !important;
}

.mc-select__nav--prev, .mc-select__nav--next {
  transition: transform .2s ease-in-out;
}

.mc-select__nav--prev:active {
  transform: translateX(-.5em);
}

.mc-calendar--inline .mc-select__month .mc-select__nav--prev, .mc-select__nav--prev {
  left: 0;
}

.mc-select__nav--next:active {
  transform: translateX(.5em);
}

.mc-calendar--inline .mc-select__month .mc-select__nav--next, .mc-select__nav--next {
  right: 0;
}

.mc-calendar--inline .mc-select__year .mc-select__nav, .mc-calendar--permanent .mc-select__year .mc-select__nav, .mc-select__year .mc-select__nav {
  display: none;
}

@media (width >= 625px) {
  .mc-select__year .mc-select__nav {
    display: flex;
  }
}

.mc-select__data {
  cursor: pointer;
  align-items: center;
  height: calc(8vw + .25rem);
  min-height: 1.75rem;
  max-height: 2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@supports not (font-size: clamp(1rem, 8vw, 1.25rem)) {
  .mc-select__data {
    font-size: 8vw;
  }

  @media (width <= 12.5rem) {
    .mc-select__data {
      font-size: 1rem;
    }
  }

  @media (width >= 15.625rem) {
    .mc-select__data {
      font-size: 1.25rem;
    }
  }
}

@supports (font-size: clamp(1rem, 8vw, 1.25rem)) {
  .mc-select__data {
    font-size: clamp(1rem, 8vw, 1.25rem);
  }
}

.mc-select__data:after {
  content: "";
  opacity: 0;
  position: absolute;
  inset: 0;
}

.mc-select__data span {
  text-align: center;
  line-height: 1.2;
  position: absolute;
}

.mc-select[data-target="year"] .mc-select__data span {
  position: relative;
}

.mc-select__data--month, .mc-select__data--month span {
  width: 40vw;
  min-width: 5rem;
  max-width: 6.25rem;
}

.mc-calendar--inline .mc-select__data--month, .mc-calendar--inline .mc-select__data--month span {
  width: 6.4rem;
}

.mc-select__data--year, .mc-select__data--year span {
  width: 22vw;
  min-width: 3rem;
  max-width: 3.5rem;
}

.mc-calendar--inline .mc-select__data--year, .mc-calendar--inline .mc-select__data--year span {
  width: 3.2rem;
}

.slide-right--in {
  animation: .2s slide-right-in;
}

.slide-right--out {
  animation: .2s slide-right-out;
}

.slide-left--in {
  animation: .2s slide-left-in;
}

.slide-left--out {
  animation: .2s slide-left-out;
}

.mc-date {
  text-align: center;
  border-radius: 5px;
  width: 14.2857%;
  font-weight: 300;
}

.mc-date--active {
  cursor: pointer;
  color: var(--mc-date-active-def-foreground);
}

.mc-date--active.mc-date--today {
  color: var(--mc-date-active-today-foreground);
  background-color: var(--mc-date-active-today-background);
}

.mc-date--active.mc-date--picked {
  color: var(--mc-date-active-pick-foreground);
  background-color: var(--mc-date-active-pick-background);
}

.mc-date--inactive {
  color: var(--mc-date-inactive-def-foreground);
  cursor: default;
}

.mc-date--inactive.mc-date--today {
  box-shadow: 0 0 0 1px var(--mc-date-inactive-today-background);
  color: var(--mc-date-inactive-today-foreground);
  box-shadow: none;
}

.mc-date--inactive.mc-date--picked {
  box-shadow: 0 0 0 1px var(--mc-date-inactive-pick-background);
  color: var(--mc-date-inactive-pick-foreground);
  box-shadow: none;
}

.mc-date--marked {
  color: var(--mc-date-marcked-foreground);
  font-weight: 500;
}

.mc-table {
  border-collapse: unset;
  height: 100%;
}

@media (width >= 625px) {
  .mc-calendar--modal .mc-table {
    border-top: none;
  }
}

.mc-calendar--inline .mc-table {
  border-top: none;
}

.mc-table__weekday {
  text-align: center;
  color: var(--mc-weekday-foreground);
  width: 14.2857%;
  padding: .5em 0;
}

.mc-month-year__preview {
  visibility: hidden;
  opacity: 0;
  background-color: var(--mc-picker-background);
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  height: 100%;
  margin: 0 5%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.mc-month-year__preview--opened {
  visibility: visible;
  opacity: 1;
}

.mc-month-year__cell {
  text-align: center;
  cursor: pointer;
  color: var(--mc-prev-active-def-foreground);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 20%;
  display: flex;
  position: relative;
}

.mc-month-year__cell:after {
  content: "";
  opacity: 0;
  border-radius: 10px;
  position: absolute;
  inset: 0;
}

.mc-month-year__cell--picked {
  color: var(--mc-prev-active-pick-foreground);
  background-color: var(--mc-prev-active-pick-background);
}

.mc-month-year__cell--inactive {
  color: var(--mc-prev-inactive-def-foreground);
  cursor: default;
}

.mc-month-year__cell--inactive.mc-month-year__cell--picked {
  color: var(--mc-prev-inactive-pick-foreground);
  box-shadow: 0 0 0 1px var(--mc-prev-inactive-pick-background);
  background-color: #0000;
}

.mc-btn {
  padding: 0 .5em;
  font-weight: 500;
  display: inline-block;
}

.mc-btn--success {
  color: var(--mc-btn-success-foreground);
  margin-left: .5em;
}

.mc-btn--danger {
  color: var(--mc-btn-danger-foreground);
  margin-right: .5em;
}

.mc-btn:active {
  transform: scale3d(.95, .95, .95);
}

.mc-btn:focus {
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

@media (width >= 625px) {
  .u-display-none {
    display: none !important;
  }
}

.mc-picker-vhidden {
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  top: 0;
  overflow: hidden;
}

/*# sourceMappingURL=index.9de35702.css.map */
