/*============================================*/
/*               Picker Components            */
/*============================================*/

.mc-picker {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: $picker-bg-color;
	color: $picker-text-color;

	#{$modal} & {
		height: 65vh;
		max-height: 400px;
		border-radius: 30px 30px 0 0;
		@include for-desktop {
			justify-content: center;
			align-items: flex-end;
			height: 100%;
			width: 425px;
			border-radius: unset;
		}
	}

	#{$inline} & {
		border-radius: unset;
	}

	&__header {
		display: flex;
		@include font-size-clamp-polyfill(1rem, 8, 1.25rem);
		justify-content: center;
		padding: 1em 0 0.5em;
		color: $picker-header-active-color;
		#{$modal} & {
			@include for-desktop {
				padding: 0.7em 0;
				justify-content: space-between;
			}
		}

		#{$inline} & {
			font-size: 1rem;
			padding: 0.7em 0 0;
		}

		.icon-angle {
			height: calc(8vw + 0.25rem);
			min-height: 1.75rem;
			max-height: 2rem;
		}
	}

	&__body {
		position: relative;
		flex: 1 0;
		width: 100%;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		padding: 1em 0 2em;

		#{$inline} & {
			padding: 0.5em 0 1em;
		}
	}
}
