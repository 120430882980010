/*============================================*/
/*             Typography Variables           */
/*============================================*/

@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap');

$calendar-font: 'Maven Pro', sans-serif;

$font-weight-light: 300;
$font-weight-regular: 300;
$font-weight-medium: 500;
